import { yupResolver } from "@hookform/resolvers/yup";
import { defaultModeratorEmailField } from "constants/event";
import { useAuth } from "contexts/auth/useAuth";
import { useEvent as useEventQuery, useEventQuestions } from "hooks/event";
import {
  Category,
  CreateEventSteps,
  CreateEventType,
  EventType,
  MultipleChoiceQuestion,
  OpenQuestion,
} from "interfaces/event";
import { User } from "interfaces/User";
import { questionsAndAnswersSchema } from "pages/MyEvents/CreateEvent/Q&A/schema";
import { eventSetupSchema } from "pages/MyEvents/CreateEvent/Settings/schema";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTimer } from "hooks/useTimer";
import { EventContextData, EventProviderProps } from "./props";

export const EventContext = createContext({} as EventContextData);

export function EventProvider({ children }: EventProviderProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentTime } = useTimer();

  // Prevent page reload
  window.onbeforeunload = () => "Tem certeza que deseja atualizar a página?";

  const [steps, setSteps] = useState<CreateEventSteps[]>(["SETUP"]);

  const { user } = useAuth();
  const { data: eventFromRequest } = useEventQuery(id);
  const { data: questionsFromRequest } = useEventQuestions(id);

  const [event, setEvent] = useState<CreateEventType | EventType>({
    isDraft: true,
    owner: user,
  } as CreateEventType);

  const [questionsToRemove, setQuestionsToRemove] = useState<
    Array<OpenQuestion | MultipleChoiceQuestion>
  >([]);

  const moderators = (event.moderators as User[])?.map(({ email }) => ({
    email,
  }));
  const categories = (event.categories as Category[])?.map(({ name }) => name);

  const eventSettingsForm = useForm<CreateEventType>({
    mode: "onChange",
    defaultValues: {
      ...event,
      currentDate: new Date(currentTime),
      categories:
        categories?.length === 0
          ? (event.categories as string[])
          : categories ?? [],
      moderators: moderators ?? [defaultModeratorEmailField],
      weightOfQuestions: (!event.weightOfQuestions) ? 100 : event.weightOfQuestions,
      groupMembersCount: event.groupMembersCount ?? null,
      linkedGroupId: (event as EventType).linkedGroup ?? null,
      useAnswersInGroup: event.useAnswersInGroup ?? false,
    },
    resolver: yupResolver(eventSetupSchema),
  });

  useEffect(() => {
    setEvent((event) => ({
      ...event,
      currentDate: new Date(currentTime),
    }));
  }, [currentTime]);

  const eventQuestionsAndAnswersForm = useForm<
    Pick<CreateEventType, "questions">
  >({
    defaultValues: {
      questions: event.questions ?? [],
    },
    resolver: yupResolver(questionsAndAnswersSchema),
  });

  useEffect(() => {
    if (eventFromRequest && questionsFromRequest) {
      setEvent({
        ...eventFromRequest,
        questions: questionsFromRequest.map((question) => ({
          ...question,
          remove: false,
        })),
        categories: eventFromRequest?.categories?.map(({ name }) => name),
        currentDate: new Date(currentTime),
      });

      eventSettingsForm.reset({
        ...eventFromRequest,
        categories: eventFromRequest?.categories?.map(({ name }) => name),
        weightOfQuestions: (!eventFromRequest.weightOfQuestions) ? 100 : eventFromRequest.weightOfQuestions,
        groupMembersCount: eventFromRequest.groupMembersCount ?? null,
        linkedGroupId: eventFromRequest.linkedGroup ?? null,
      });

      eventQuestionsAndAnswersForm.reset({
        questions: questionsFromRequest?.length
          ? questionsFromRequest.map((question) => ({
            ...question,
            remove: false,
            files: [],
          }))
          : [],
      });
    }
  }, [eventFromRequest, questionsFromRequest]);

  const getSteps = useCallback(async () => {
    if (eventFromRequest && questionsFromRequest) {
      await eventSetupSchema
        .validate({
          ...eventFromRequest,
          categories,
        })
        .then(async () => {
          await questionsAndAnswersSchema
            .validate(eventFromRequest.questions)
            .then(() => {
              setSteps(
                (steps) =>
                  [...new Set([...steps, "PUBLISH"])] as CreateEventSteps[]
              );
            });
          setSteps(
            (steps) => [...new Set([...steps, "Q&A"])] as CreateEventSteps[]
          );
        })
        .catch(() => {
          navigate("configuracoes");
          eventSettingsForm.trigger([
            "name",
            "responsePeriodStart",
            "responsePeriodEnd",
            "correctionPeriodStart",
            "correctionPeriodEnd",
          ]);
        });
    }
  }, [eventFromRequest, questionsFromRequest]);

  useEffect(() => {
    getSteps();
  }, [getSteps]);

  const contextValues = useCallback(
    () => ({
      steps,
      setSteps,
      event,
      setEvent,
      eventSettingsForm,
      eventQuestionsAndAnswersForm,
      questionsToRemove,
      setQuestionsToRemove,
    }),
    [
      steps,
      setSteps,
      event,
      setEvent,
      eventSettingsForm,
      eventQuestionsAndAnswersForm,
      questionsToRemove,
      setQuestionsToRemove,
    ]
  );

  return (
    <EventContext.Provider value={contextValues()}>
      {children}
    </EventContext.Provider>
  );
}

export function useEvent() {
  return useContext(EventContext);
}
